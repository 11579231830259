import React from "react";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import http from "./services/HttpService";
import key from "./services/KeyService";
import KeyInput from "./components/KeyInput";

var dateFormat = require("dateformat");

class App extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.getMessages = this.getMessages.bind(this);
    this.onKeyInput = this.onKeyInput.bind(this);

    let dateStart = new Date();
    dateStart.setMonth(dateStart.getMonth() - 3);
    dateStart.setHours(0, 0, 0, 0);

    let dateEnd = new Date();
    dateEnd.setMonth(dateEnd.getMonth() + 3);
    dateEnd.setHours(23, 59, 59);

    this.state = {
      isBusy: false,
      showKeyInput: false,
      dateStart: dateStart,
      dateEnd: dateEnd,
      itemCount: 100,
      severity: 6,
      hostName: "",
      appName: "",
      searchText: "",
      messages: [],
    };

    console.log(this.state);
  }

  componentDidMount() {
    this.getMessages();

    // Elke minuut ophalen
    window.setInterval(() => this.getMessages(), 60 * 1000);
  }

  getMessages() {
    var searchRequest = {
      dateStart: this.state.dateStart,
      dateEnd: this.state.dateEnd,
      itemCount: this.state.itemCount,
      severity: this.state.severity,
      hostName: this.state.hostName === "" ? "" : this.state.hostName + "%",
      appName: this.state.appName === "" ? "" : this.state.appName + "%",
      searchText:
        this.state.searchText === "" ? "" : "%" + this.state.searchText + "%",
    };

    this.setState({ isBusy: true });

    http
      .post("log-messages/search", searchRequest)
      .then((result) => {
        //console.log(result);
        this.setState({ isBusy: false, messages: result.data });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isBusy: false, showKeyInput: true });
      });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "dateStart" || name === "dateEnd") {
      this.setState({
        [name]: new Date(value),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  getSeverity() {
    return [
      { id: 0, omschrijving: "Emergency" },
      { id: 1, omschrijving: "Alert" },
      { id: 2, omschrijving: "Critical" },
      { id: 3, omschrijving: "Error" },
      { id: 4, omschrijving: "Warning" },
      { id: 5, omschrijving: "Notice" },
      { id: 6, omschrijving: "Informational" },
      { id: 7, omschrijving: "Debug" },
    ];
  }

  getRowStyle(message, index) {
    let fg = "black";
    let bg = "#fff";

    let colorIndex = index % 2;
    if (colorIndex === 1) bg = "#f7f7f7";

    // Notive en Warning
    if (message.Severity < 6) {
      bg = "#ffea00";
    }

    // Error en Critical
    if (message.Severity < 4) {
      bg = "#eb7f00";
      fg = "white";
    }

    if (message.Severity < 2) {
      // Alert en Emergency
      bg = "#990000";
      fg = "white";
    }

    return {
      backgroundColor: bg,
      color: fg,
      marginTop: "1px"
    };
  }

  getTextColor(message, index) {
    let fg = "#888";
    if (message.Severity < 4) fg = "#fff";
    return { color: fg };
  }

  onKeyInput(apiKey) {
    console.log(apiKey);
    key.setKey(apiKey);
    this.setState({ showKeyInput: false });
    this.getMessages();
  }

  render() {
    const severityOptions = this.getSeverity().map((s) => (
      <option key={s.id} value={s.id}>
        {s.omschrijving}
      </option>
    ));

    const messageDivRows = this.state.messages.map((m, i) => (
      <div
        key={m.InternalMessageId}
        className="row pt-1 pb-1"
        style={this.getRowStyle(m, i)}
      >
        <div className="col">
          <span className="text-nowrap">
            {m.Hostname.toUpperCase()} ({m.Appname})
          </span>
          <br />
          <span className="text-nowrap">
            <small style={this.getTextColor(m, i)}>
              {dateFormat(m.Timestamp, "dd-mm-yyyy HH:MM")}
            </small>
          </span>
        </div>
        <div className="col-md-8 col-lg-9 col-xl-9">{m.Message}</div>
      </div>
    ));

    const messageDiv = (
      <div className="container-fluid">
        {this.state.isBusy ? "Berichten aan het ophalen..." : messageDivRows}
      </div>
    );

    const zoekScherm = (
      <div>
        {" "}
        <div className="card">
          <div className="card-header card-header-sm bg-custom text-white">
            Zoeken
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="dateStart"
                    className="col-4 col-form-label col-form-label-sm"
                  >
                    Startdatum
                  </label>
                  <div className="col-8">
                    <input
                      className="form-control form-control-sm"
                      type="date"
                      id="dateStart"
                      name="dateStart"
                      value={this.state.dateStart.toISOString().split("T")[0]}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="dateEnd"
                    className="col-4 col-form-label col-form-label-sm"
                  >
                    Einddatum
                  </label>
                  <div className="col-8">
                    <input
                      className="form-control form-control-sm"
                      type="date"
                      id="dateEnd"
                      name="dateEnd"
                      value={this.state.dateEnd.toISOString().split("T")[0]}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="itemCount"
                    className="col-4 col-form-label col-form-label-sm"
                  >
                    Aantal berichten
                  </label>
                  <div className="col-8">
                    <input
                      className="form-control form-control-sm"
                      type="number"
                      id="itemCount"
                      name="itemCount"
                      value={this.state.itemCount}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="severity"
                    className="col-4 col-form-label col-form-label-sm"
                  >
                    Severity
                  </label>
                  <div className="col-8">
                    <select
                      className="form-control form-control-sm"
                      name="severity"
                      id="severity"
                      value={this.state.severity}
                      onChange={this.handleInputChange}
                    >
                      {severityOptions}
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="hostName"
                    className="col-4 col-form-label col-form-label-sm"
                  >
                    Host
                  </label>
                  <div className="col-8">
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="hostName"
                      name="hostName"
                      value={this.state.hostName}
                      onChange={this.handleInputChange}
                      placeholder="Vul een hostname in"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="appName"
                    className="col-4 col-form-label col-form-label-sm"
                  >
                    Applicatie
                  </label>
                  <div className="col-8">
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="appName"
                      name="appName"
                      value={this.state.appName}
                      onChange={this.handleInputChange}
                      placeholder="Vul een applicatienaam in"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="searchText"
                    className="col-4 col-form-label col-form-label-sm"
                  >
                    Zoeken
                  </label>
                  <div className="col-8">
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="searchText"
                      name="searchText"
                      value={this.state.searchText}
                      onChange={this.handleInputChange}
                      placeholder="Vul een zoektekst in"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <button
                  className="btn btn-primary bg-custom"
                  onClick={this.getMessages}
                >
                  Zoeken
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-header card-header-sm bg-custom text-white">
            Berichten
          </div>
          <div className="card-body p-0">{messageDiv}</div>
        </div>
      </div>
    );

    const keyInput = (
      <KeyInput show={this.state.showKeyInput} onConfirm={this.onKeyInput} />
    );

    return (
      <div className="container-fluid p-3">
        {zoekScherm}
        {keyInput}
      </div>
    );
  }
}

export default App;
