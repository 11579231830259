import Axios from "axios";
import key from "./KeyService";

class HttpService {
  constructor() {
    console.log("HttpService init");

    Axios.defaults.baseURL = "https://log.autotaal.biz/api";
  }
  get(url) {
    let config = {
      url: url,
      method: "get",
      headers: {
        "X-API-KEY": key.getKey(),
      },
    };

    return Axios(config);
  }

  post(url, data) {
    let config = {
      url: url,
      method: "post",
      data: data,
      headers: {
        "X-API-KEY": key.getKey(),
      },
    };

    return Axios(config);
  }
}

const http = new HttpService();
export default http;
