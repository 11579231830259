class KeyService {
  getKey() {
    return window.localStorage.getItem("api-key");
  }

  setKey(apiKey) {
    window.localStorage.setItem("api-key", apiKey);
  }
}

const key = new KeyService();
export default key;
