import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";

class KeyInput extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      currentKey: "",
    };
  }

  onClose() {
    if (this.props.onClose) this.props.onClose();
  }

  onConfirm() {
    if (this.props.onConfirm) this.props.onConfirm(this.state.currentKey);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    let warning;
    if (this.props.show === undefined) warning = "show property is required!";
    if (this.props.onConfirm === undefined)
      warning = "onConfirm property is required!";
    if (warning) {
      return <Alert variant="danger">{warning}</Alert>;
    }

    const modal = (
      <Modal show={this.props.show} onHide={this.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Vul een geldige api key in</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <label htmlFor="currentKey" className="col-2 col-form-label">
              Key
            </label>
            <div className="col-10">
              <input
                className="form-control"
                type="text"
                id="currentKey"
                name="currentKey"
                value={this.state.currentKey}
                onChange={this.handleInputChange}
                placeholder="Vul een key in"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="bg-custom" onClick={this.onConfirm}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );

    return modal;
  }
}

export default KeyInput;
